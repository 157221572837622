import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { PATH_CLAIMS_SEARCH_CLIENTS } from '../../../../app';

import styles from './vet-claims-submission.css';

function VetClaimsSubmission() {
  const dispatch = useDispatch();
  const { current: petId } = useRef(
    sessionStorage.getItem('@pumpkincare/claim/vet/pet')
  );

  useEffect(() => {
    if (!petId) {
      dispatch(push(PATH_CLAIMS_SEARCH_CLIENTS));
    }

    sessionStorage.clear();
  }, [petId, dispatch]);

  return <div className={styles.root}>VetClaimsSubmission</div>;
}

export default VetClaimsSubmission;
