import { Route, Switch } from 'react-router-dom';

import { PATH_CLAIMS_FILE, PATH_CLAIMS_SEARCH_CLIENTS } from '../../../app';
import ClaimsSearchClient from './claims-search-client';
import VetClaimsSubmission from './vet-claims-submission';

export default function PatientsWrapper() {
  return (
    <Switch>
      <Route
        path={PATH_CLAIMS_SEARCH_CLIENTS}
        render={() => <ClaimsSearchClient />}
      />

      <Route path={PATH_CLAIMS_FILE} render={() => <VetClaimsSubmission />} />
    </Switch>
  );
}
