import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { A, Body1, ButtonPrimary } from '@pumpkincare/portal-ui';
import { CONTACT_VET_EMAIL, CONTACT_VET_EMAIL_LINK } from '@pumpkincare/shared';

import { FACEBOOK_LINK, INSTAGRAM_LINK, TWITTER_LINK } from '../../constants';
import { PATH_ACCOUNT_BASE } from '../../routing';
import { getRouterLocationPathname } from '../../selectors/router-selectors';

import styles from './error-page.css';

import facebook from '../../../../../../../public/assets/paw-portal/logos/facebook.png';
import instagram from '../../../../../../../public/assets/paw-portal/logos/instagram.png';
import twitter from '../../../../../../../public/assets/paw-portal/logos/twitter.png';
import mozart from '../../../../../../../public/assets/paw-portal/pet-portraits/mozart.png';

export default function ErrorPage({ content, isPrivate = true }) {
  const pathname = useSelector(getRouterLocationPathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      {content || (
        <>
          <h1 className={styles.title}>Ruh Roh!</h1>

          <Body1 className={styles.content}>
            {`Looks like something went wrong. Try ${
              isPrivate ? 'visiting the homepage or ' : ''
            }contacting
            our Pumpkin Care Team.`}
          </Body1>

          {isPrivate && pathname !== PATH_ACCOUNT_BASE ? (
            <ButtonPrimary className={styles.button}>
              <Link to={PATH_ACCOUNT_BASE} className={styles.link}>
                Visit Homepage
              </Link>
            </ButtonPrimary>
          ) : null}
        </>
      )}

      <h4 className={styles.contactTitle}>
        Have questions? <br />
        We're a paw away.
      </h4>

      <A href={CONTACT_VET_EMAIL_LINK} className={styles.link}>
        {CONTACT_VET_EMAIL}
      </A>

      <div className={styles.social}>
        <A href={FACEBOOK_LINK} target='_blank'>
          <img
            src={facebook}
            alt='facebook'
            role='presentation'
            className={styles.icon}
          />
        </A>

        <A href={TWITTER_LINK} target='_blank'>
          <img
            src={twitter}
            alt='twitter'
            role='presentation'
            className={styles.icon}
          />
        </A>

        <A href={INSTAGRAM_LINK} target='_blank'>
          <img
            src={instagram}
            alt='instagram'
            role='presentation'
            className={styles.icon}
          />
        </A>
      </div>

      <img src={mozart} alt='cat' role='presentation' className={styles.cat} />
      <span className={styles.yellowCircle} />
      <span className={styles.blueCircle} />
      <span className={styles.redCircle} />
    </div>
  );
}
