// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vet-claims-submission_root_69ae87c9 {\n\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .vet-claims-submission_root_69ae87c9 {\n\n    }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n    .vet-claims-submission_root_69ae87c9 {\n\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/claims/view/claims-wrapper/vet-claims-submission/vet-claims-submission.css"],"names":[],"mappings":"AAEA;;AAEA;;AAEA;IACI;;IAEA;AACJ;;AAEA;IACI;;IAEA;AACJ","sourcesContent":["@value tablet, desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n\n}\n\n@media tablet {\n    .root {\n\n    }\n}\n\n@media desktop {\n    .root {\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"root": "vet-claims-submission_root_69ae87c9"
};
export default ___CSS_LOADER_EXPORT___;
