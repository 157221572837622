import { useMutation } from 'react-query';

import { formatSearchFromQuery } from '@pumpkincare/portal-shared';
import { getPawPortalClients } from '@pumpkincare/user';

export function transformClients(clients) {
  return clients.map(client => {
    const pets = client.pets
      .map(pet => ({
        ...pet,
        canVetFileClaim: !!pet.policies.length,
      }))
      .sort((a, b) => {
        if (a.canVetFileClaim === b.canVetFileClaim) {
          return a.name.localeCompare(b.name);
        }
        return a.canVetFileClaim ? -1 : 1;
      });

    return {
      ...client,
      hasFileablePet: pets.some(pet => pet.canVetFileClaim),
      pets,
    };
  });
}

export default function useSearchClients() {
  return useMutation(params =>
    getPawPortalClients(`?${formatSearchFromQuery(params)}`).then(transformClients)
  );
}
