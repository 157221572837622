import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import {
  BannerType,
  CONTACT_VET_EMAIL,
  CONTACT_VET_EMAIL_LINK,
  useBanners,
  useTargetState,
} from '@pumpkincare/shared';
import {
  Body1,
  ButtonStyles,
  Chip,
  LegalBody,
  LoaderButton,
  TextField,
} from '@pumpkincare/shared/ui';

import { PATH_CLAIMS_FILE } from '../../../../app';
import useSearchClients from './use-search-clients';

import styles from './claims-search-client.css';

const INITIAL_ERRORS = {
  isAccount: false,
  isEmail: false,
  isGeneric: false,
};

function ClaimsSearchClient() {
  const dispatch = useDispatch();

  const [account, setAccount] = useTargetState('');
  const [email, setEmail] = useTargetState('');
  const [errors, setErrors] = useState(INITIAL_ERRORS);
  const [selectedPet, setSelectedPet] = useState('');

  const { addBanner, removeAllBanners } = useBanners();

  const {
    data: clientsData,
    mutateAsync: mutateGetClients,
    isLoading: isLoadingClients,
  } = useSearchClients();
  const client = clientsData?.[0];

  function addErrorForNoAccount() {
    const errorKey = account ? 'isAccount' : 'isEmail';
    setErrors({
      ...INITIAL_ERRORS,
      [errorKey]: true,
    });
  }

  function handleSubmitUserSearch(e) {
    e.preventDefault();
    setErrors(INITIAL_ERRORS);
    removeAllBanners();

    const payload = account ? { pumpkin_id: account } : { email };

    mutateGetClients(payload)
      .then(data => {
        if (!data.length) {
          addErrorForNoAccount();
        } else if (!data[0]?.hasFileablePet) {
          addBanner({
            type: BannerType.WARNING,
            message:
              'Unfortunately, you can only file a claim or estimate for pets with insurance at this time. We currently don’t support filing for Pumpkin Wellness Club at this time.',
          });
        }
      })
      .catch(err => {
        if (err.response?.status >= 500) {
          addBanner({
            type: BannerType.WARNING,
            message: (
              <>
                Something went wrong. Please try again, or reach out to{' '}
                <a href={CONTACT_VET_EMAIL_LINK}>{CONTACT_VET_EMAIL}</a>
              </>
            ),
          });
        } else {
          const errorKey = account ? 'isAccount' : 'isEmail';
          setErrors({
            ...INITIAL_ERRORS,
            [errorKey]: true,
          });
        }
      });
  }

  function handleGoToClaims() {
    if (!selectedPet) return false;

    sessionStorage.setItem('@pumpkincare/claim/vet/pet', selectedPet);
    dispatch(push(PATH_CLAIMS_FILE));
  }

  return (
    <div className={styles.root}>
      {!client || !client.hasFileablePet ? (
        <form onSubmit={handleSubmitUserSearch}>
          <h4>Pumpkin Account</h4>

          <TextField
            id='account'
            name='account'
            label="Client's Pumpkin Account Number"
            value={account}
            onChange={setAccount}
            legalMessage={
              <LegalBody className={styles.legal}>
                This can be found under the client's account profile
              </LegalBody>
            }
            error={{ errorMessage: errors.isAccount ? 'Account not found' : null }}
          />

          <div className={styles.border}>
            <span>OK</span>
            <div className={styles.borderLine} />
          </div>

          <TextField
            id='email'
            name='email'
            label='Pumpkin Account Email'
            value={email}
            onChange={setEmail}
            legalMessage={
              <LegalBody className={styles.legal}>
                This will be the email used for the client's account
              </LegalBody>
            }
            error={{ errorMessage: errors.isEmail ? 'Account not found' : null }}
          />

          <LoaderButton
            disabled={!(account || email)}
            isLoading={isLoadingClients}
            color='primary'
            classes={{ root: styles.cta }}
          >
            Next
          </LoaderButton>
        </form>
      ) : (
        <>
          <h4>Choose a pet</h4>

          <div className={styles.pets}>
            {client.pets.map(pet => (
              <Chip
                key={pet.id}
                onClick={() => setSelectedPet(pet.id)}
                selected={selectedPet === pet.id}
                classes={{ root: styles.pet }}
                disabled={!pet.canVetFileClaim}
                large
              >
                <Body1 isBold>{pet.name}</Body1>
              </Chip>
            ))}
          </div>

          <button
            className={classNames(styles.cta, ButtonStyles.primary)}
            disabled={!selectedPet}
            onClick={handleGoToClaims}
          >
            Next
          </button>
        </>
      )}
    </div>
  );
}

export default ClaimsSearchClient;
