import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { analyticsTrack, errorBannerShow } from '@pumpkincare/analytics';
import { useBanners } from '@pumpkincare/shared';
import { GlobalBanner } from '@pumpkincare/shared/ui';

import { getRouterLocationPathname } from '../../selectors';
import ErrorBoundary from '../error-boundary';
import Footer from '../footer';
import NavBar from '../nav-bar';

function PageComponent({
  component: Component,
  hideNavBar,
  isBasicPawPortalNav,
  basicHeader,
  hideFooter,
  isCustomer,
  isPrivate = false,
}) {
  const route = useSelector(getRouterLocationPathname);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  const { banners, removeBanner } = useBanners();

  const handleBannerError = useCallback(banner => {
    analyticsTrack(errorBannerShow(banner));
  }, []);

  return (
    <>
      {hideNavBar || isCustomer ? null : (
        <NavBar
          isBasicPawPortalNav={isBasicPawPortalNav}
          basicHeader={basicHeader}
        />
      )}

      {banners.map(banner => {
        return (
          <GlobalBanner
            key={banner.type + banner.icon}
            banner={banner}
            onClose={() => removeBanner(banner)}
            onError={handleBannerError}
          />
        );
      })}

      <ErrorBoundary isPrivate={isPrivate}>
        <Component />
      </ErrorBoundary>

      {!hideFooter ? <Footer isCustomer={isCustomer} /> : null}
    </>
  );
}

export default PageComponent;
