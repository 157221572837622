import { useParams } from 'react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body1, ButtonSecondary } from '@pumpkincare/portal-ui';

import { RECORDS_EMAIL, RECORDS_EMAIL_LINK } from '../../../app';

import styles from './records-request-complete.css';

import celebrate from '../../../../../../../public/assets/paw-portal/illustrations/multipet/pet_lifestyle/celebrate_1.png';
import peekaboo from '../../../../../../../public/assets/paw-portal/illustrations/multipet/pet_lifestyle/peek-a-boo.png';
import checkup from '../../../../../../../public/assets/paw-portal/illustrations/multipet/pets_with_jobs/wellness_checkup.png';

const NOT_CURRENT_AVAILABLE = {
  header: 'Got your note!',
  subtitle:
    "Thank you for letting us know why you weren't able to complete our medical records request – we'll reach out again in 2 days to remind you to send us the records once the doctor’s notes are finalized.",
  action:
    'As soon as you have the records available, please return to the upload page.',
};
const DEFAULT = {
  header: 'Got your note!',
  subtitle:
    "Thank you for letting us know why you weren't able to complete our medical records request – we'll take it from here! If we need further assistance, we'll contact you via email.",
  action: 'Have more medical records to share?',
};

function RecordsRequestComplete({
  isExpired,
  closedWith,
  firstName,
  handleReturnToRequest,
  classes = {},
}) {
  const { recordRequestId } = useParams();
  const id = recordRequestId;

  if (isExpired && closedWith?.match(/reason|documents/i)) {
    return (
      <div className={classNames(styles.root, classes.root)}>
        <img src={peekaboo} alt='' />

        <h1>You've already shared records for this pet.</h1>
        <Body1 className={styles.text}>
          Thanks to you, we can now process {firstName}'s claim! If we need further
          assistance, we'll contact you via email.
        </Body1>

        <h5>Have more medical records to share?</h5>
        <Body1 className={styles.text}>
          You can send them to{' '}
          <a href={RECORDS_EMAIL_LINK(id)}>{RECORDS_EMAIL(id)}</a>. Be sure to
          mention which pet & owner they're for.
        </Body1>
      </div>
    );
  }

  if (isExpired) {
    return (
      <div className={classNames(styles.root, classes.root)}>
        <img src={peekaboo} alt='' />

        <h1>This upload link has expired.</h1>

        <Body1 className={styles.text}>
          If you need to share medical records for this pet, you can send them to{' '}
          <a href={RECORDS_EMAIL_LINK(id)}>{RECORDS_EMAIL(id)}</a>. Be sure to
          mention which pet & owner they're for.
        </Body1>
      </div>
    );
  }

  if (closedWith.match(/documents/i)) {
    return (
      <div className={classNames(styles.root, classes.root)}>
        <h1>Upload successful!</h1>
        <Body1 className={styles.text}>
          Thanks to you, we can now process {firstName}'s claim! If we need further
          assistance, we'll contact you via email.
        </Body1>
        <h5>Have more medical records to share?</h5>
        <ButtonSecondary onClick={handleReturnToRequest}>
          Return to Upload
        </ButtonSecondary>

        <img src={celebrate} alt='' />
      </div>
    );
  }

  // this last section will only render if (closedWith.match(/reason/i)) based on parent component conditions
  const copy = closedWith.match(/available/i) ? NOT_CURRENT_AVAILABLE : DEFAULT;
  return (
    <div className={classNames(styles.root, classes.root)}>
      <h1>{copy.header}</h1>
      <Body1 className={styles.text}>{copy.subtitle}</Body1>
      <h5>{copy.action}</h5>
      <ButtonSecondary onClick={handleReturnToRequest}>
        Return to Upload
      </ButtonSecondary>

      <img src={checkup} alt='' />
    </div>
  );
}

RecordsRequestComplete.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),

  isExpired: PropTypes.bool.isRequired,
  closedWith: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  handleReturnToRequest: PropTypes.func.isRequired,
};

export default RecordsRequestComplete;
